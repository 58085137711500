<template>
  <div>
    <b-button
        @click="$bvModal.show('modal-add-group'+tableIndex)"
        class="green-button-transparent border-0 ml-3"
        :disabled="!isSysAdmin && !$hasPrivileges(
          $privileges('CAN_ADD_SUBGROUP_TO_GROUP'),
          isSysAdmin,
          userPrivileges,
        )"
    >
      <b-icon
          icon="plus-circle-fill"
          class="mr-2 top--3"
          aria-hidden="true"
          scale="1"
      ></b-icon>
      {{ $t('buttons.addGroup') }}
    </b-button>
    <b-modal
        :id="'modal-add-group' + tableIndex"
        :ref="'modal-add-group' + tableIndex"
        footer-class="flex-nowrap"
        centered
    >
      <template #modal-header>
        <h3>{{ $t('modals.createGroup.title') }}</h3>
      </template>

      <template #default>
        <div class="mt-4 mx-5 mb-4">
          <b-form-input
              v-model="newGroupName"
              :placeholder="$t('modals.createGroup.name')"
              @keyup.enter="createGroup"
              @input="makeFieldDirty"
              autofocus
              :state="groupDuplicateError"
              debounce="500"
          >
          </b-form-input>
          <b-form-invalid-feedback>
            {{ groupCreateError }}
          </b-form-invalid-feedback>
          <div v-if="groupSlugExists" class="invalid">
            {{ $t('groupManagement.duplicateNameWithParam', { groupName: newGroupName }) }}
          </div>
        </div>
        <div class="preview text-center mb-3"><small>{{ computedUrl }}</small></div>
      </template>

      <template #modal-footer="{ close }">
        <b-button
            class="p-3 border-right-light-grey"
            block
            @click="closeModal"
        >
          {{ $t('buttons.cancel') }}
        </b-button>
        <b-button
            :disabled="isButtonDisabled"
            variant="primary"
            class="p-3"
            block
            @click="createGroup"
        >
          {{ $t('modals.createGroup.validate') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import slugify from '@sindresorhus/slugify';
import GetAllSlugsOfAllGroups from '@/graphQlQueries/queries/getAllSlugsOfAllGroups';
import CheckGroupSlugExists from '@/graphQlQueries/queries/checkGroupSlugExists';

export default {
  name: 'ModalAddNewGroup',
  props: ['tableIndex', 'isSysAdmin', 'userPrivileges', 'groupCreateError'],
  data: () => ({
    fieldDirty: false,
    newGroupName: '',
    slugsOfAllGroups: [],
    groupSlug: null,
    groupDomain: null,
    groupSlugExists: false,
  }),
  apollo: {
    slugsOfAllGroups: {
      query: GetAllSlugsOfAllGroups,
      update(data) {
        const slugs = [];
        data.groups.forEach((group) => {
          slugs.push(group.slug);
        });
        return slugs;
      },
      fetchPolicy: 'network-only',
    },
  },
  computed: {
    groupDuplicateError() {
      const stateOfInput = !this.groupCreateError || this.fieldDirty;
      if (this.groupSlugExists) {
        return false;
      }
      if (stateOfInput === false) {
        return false;
      }
      if (this.newGroupName === '') {
        return null;
      }
      return true;
    },
    isButtonDisabled() {
      if (this.newGroupName !== '') {
        if (this.groupSlugExists || this.groupCreateError) {
          return true;
        }
        return false;
      }
      return true;
    },
    computedSlugOfName() {
      if (this.newGroupName) {
        const itemSlugOriginal = slugify(this.newGroupName);

        // check if slug already exists
        let slugCount = 0; // initial count
        let itemSlug = null;
        let itemWithSameSlug = null;

        while (itemWithSameSlug === null) {
          // at the first time we have to check if the original slug exists
          itemSlug = (slugCount === 0)
            ? itemSlugOriginal
            : `${itemSlugOriginal}-${slugCount}`;

          itemWithSameSlug = this.slugsOfAllGroups.find(
            // eslint-disable-next-line no-loop-func
            (slug) => slug === itemSlug,
          );
          slugCount++;
        }
        return itemSlug;
      }
      return '';
    },
    computedUrl() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.groupSlug = this.computedSlugOfName;
      if (this.groupSlug) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.groupDomain = process.env.VUE_APP_DOMAIN.replace('[group_slug]', this.groupSlug);
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.groupDomain = process.env.VUE_APP_DOMAIN.replace('[group_slug]', '');
      }
      return this.groupDomain;
    },
  },
  watch: {
    async newGroupName() {
      this.groupSlugExists = false;
      if (this.newGroupName === '') {
        return;
      }
      const {
        data: { checkGroupSlugExists: groupSlugExists },
      } = await this.$apollo.query({
        query: CheckGroupSlugExists,
        variables: {
          groupSlug: this.newGroupName,
        },
        fetchPolicy: 'network-only',
      });
      this.groupSlugExists = groupSlugExists;
    },
  },
  methods: {
    makeFieldDirty() {
      this.fieldDirty = true;
    },
    closeModal() {
      this.fieldDirty = false;
      this.newGroupName = '';
      const modalRef = `modal-add-group${this.tableIndex}`;
      this.$emit('resetErrorMsg');
      this.$refs[modalRef].hide();
    },
    createGroup() {
      if (!this.isButtonDisabled) {
        const modalRef = `modal-add-group${this.tableIndex}`;
        this.$emit('createGroup', this.newGroupName, this.groupSlug, this.groupDomain, modalRef);
        this.fieldDirty = false;
        this.newGroupName = '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.invalid {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
